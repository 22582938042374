<template>
  <div>
    <b-card>
      <v-select
        id="search"
        v-model="searchUser"
        :options="optionsUser"
        autofocus
        placeholder="Name..."
        @search="searchUsers"
        :reduce="(value) => value.id"
        @input="onChangeUser"
        label="name"
      >
        <template slot="option" slot-scope="option">
          <div class="d-flex">
            <b-avatar size="30" variant="light" :src="option.avatar" />
            <span class="ml-1"> {{ option.name }} {{ option.lastname }}</span>
          </div>
        </template>
        <template slot="selected-option" slot-scope="option">
          <div class="selected d-center">
            <b-avatar size="30" variant="light" :src="option.avatar" />
            <span class="ml-1"> {{ option.name }} {{ option.lastname }}</span>
          </div>
        </template>
      </v-select>
    </b-card>
    <b-card v-if="searchUser">
      <b-progress
        show-progress
        class="my-2 rounded-lg roundedCustom"
        :max="totalScore"
        height="3rem"
        :variant="getVariantType(totalScoreUser)"
      >
        <b-progress-bar
          :value="totalScoreUser"
          :label="`${totalScoreUser} / ${totalScore}`"
        ></b-progress-bar>
      </b-progress>
      <b-table
        :items="userItem"
        :fields="fields"
        class="position-relative"
        responsive
        show-empty
        empty-text="No matching records found"
        bordered
      >
        <template #cell(score)="data">
          <b-form-group v-slot="{  }">
            <div class="d-flex">
              <b-form-radio-group
                value-field="item"
                text-field="name"
                :id="`radio-group-${data.index}`"
                v-model="selected[data.index]"
                @change="
                  userScore(data.item.pivot.goal_id, selected[data.index])
                "
              >
                <b-form-radio
                  v-for="(option, index) in scorePerItem[data.index]"
                  :key="index"
                  :id="`radio-${data.index}-${index}`"
                  :value="option.item"
                  :name="`radio-group-${data.index}`"
                  v-b-tooltip.hover.top="option.hover"
                >
                  {{ option.name }}
                </b-form-radio>
              </b-form-radio-group>
            </div>
            <!-- <b-form-radio-group
              value-field="item"
              text-field="name"
              :title="scorePerItem[data.index][data.index].hover"
              :id="`radio-${data.index}`"
              :options="scorePerItem[data.index]"
              v-model="selected[data.index]"
              :aria-describedby="ariaDescribedby"
              v-b-tooltip.hover.top="scorePerItem[data.index].hover"
              @change="userScore(data.item.pivot.goal_id, selected[data.index])"
            >
            </b-form-radio-group> -->
          </b-form-group>
        </template>
      </b-table>
      <ToastNotification ref="toast" />
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BFormInput,
  BAvatar,
  BTable,
  BFormGroup,
  BFormRadio,
  BFormRadioGroup,
  BProgress,
  BProgressBar,
  VBTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";
import ToastNotification from "@/components/ToastNotification";

import axiosU from "@/services/admin/user";
import axiosS from "@/services/admin/goals/goalScore";

import { notificationCheck } from "@/mixins/NotificationSetup";

export default {
  components: {
    BCard,
    BFormInput,
    BAvatar,
    BTable,
    BFormGroup,
    BFormRadio,
    BFormRadioGroup,
    BProgress,
    BProgressBar,
    vSelect,
    ToastNotification,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  watch: {
    searchUser() {
      this.showScoreUser();
    },
  },
  data() {
    return {
      searchUser: null,
      clearTimeoutBuscador: "",
      optionsUser: [],
      scorePerItem: {},
      selected: {},
      userItem: null,
      fields: [
        { key: "name" },
        { key: "description" },
        { key: "score", label: "Score" },
      ],
      totalScoreUser: 0,
      totalScore: 0,
      scoreHover: {
        Puntualidad: [
          "Tiene mas de 5 llegadas tarde o errores al marcar",
          "Tiene 4 llegadas tarde o errores al marcar",
          "Tiene 3 llegadas tarde o errores al marcar",
          "Tiene 1-2 llegadas tarde o errores al marcar",
          "No tiene llegadas tarde",
        ],
        ["Cumplimiento de obligaciones"]: [
          "No cumple en 4 ocasiones",
          "No cumple en 3 ocasiones",
          "No cumple en 2 ocasiones",
          "No cumple en 1 ocasión",
          "Cumple satisfactoriamente",
        ],
        ['Desempeño Individual']:[
          "No cumple en 4 ocasiones",
          "No cumple en 3 ocasiones",
          "No cumple en 2 ocasiones",
          "No cumple en 1 ocasión",
          "Cumple satisfactoriamente",
        ],
        ['Desempeño con el paciente']:[
          "Tiene 4 reviews",
          "Tiene 3 reviews",
          "Tiene 2 reviews",
          "Tiene 1 review",
          "No tiene reviews de 1 a 3 estrellas",
        ]
      },
    };
  },
  methods: {
    searchUsers(name, loading) {
      if (name.length) {
        const datos = {
          name,
          role: ["OMA", "NURSE", "FRONT-DESK"],
        };
        loading(true);
        clearTimeout(this.clearTimeoutBuscador);
        this.clearTimeoutBuscador = setTimeout(() => {
          axiosU.userFilter(10, datos).then(({ registro: { data } }) => {
            this.optionsUser = data;
            loading(false);
          });
        }, 400);
      }
    },
    showScoreUser() {
      if (this.searchUser) {
        this.selected = {};
        this.scorePerItem = {};
        axiosS.goalUserInfo(this.searchUser).then(({ registro }) => {
          const { objectifiable } = registro;
          this.userInfo = registro;
          this.userItem = objectifiable;
          const data = {};
          objectifiable.forEach((item, index) => {
            this.totalScoreUser += item.pivot.score;
            this.totalScore += item.pivot.goal;
            data[index] = [];
            const { pivot } = item;
            this.selected[index] = pivot.score;
            for (let i = 0; i <= pivot.goal; i++) {
              data[index].push({
                item: i,
                hover: this.scoreHover[item.name]
                  ? this.scoreHover[item.name][i]
                  : "",
                name: i,
              });
            }
          });
          this.scorePerItem = data;
        });
      }
    },
    userScore(idItem, itemScore) {
      this.totalScoreUser = 0;
      Object.values(this.selected).forEach((item) => {
        this.totalScoreUser += item;
      });
      const data = {
        goal_id: idItem,
        score: itemScore,
      };
      axiosS.goalUserUpdate(this.searchUser, data).then((res) => {
        const { type, message } = notificationCheck(res);
        this.$refs.toast[type](message);
      });
    },
    onChangeUser() {
      this.totalScoreUser = 0;
      this.totalScore = 0;
    },
    getVariantType(item) {
      if (item >= 20) return "success";
      if (item >= 14) return "warning";
      if (item >= 1) return "danger";
      return "";
    },
  },
};
</script>

<style></style>
